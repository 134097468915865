import { createContext, useContext, useMemo, useState } from 'react';

const HelperContext = createContext();

export function HelperContextWrapper({ children }) {
  const [boxes, setBoxes] = useState([]);
  const [xAdjustment, setXAdjustment] = useState(0);
  const [yAdjustment, setYAdjustment] = useState(0);

  const [outlineModel, setOutlineModel] = useState();

  const value = useMemo(
    () => ({
      boxes,
      setBoxes,
      xAdjustment,
      setXAdjustment,
      yAdjustment,
      setYAdjustment,
      outlineModel,
      setOutlineModel
    }),
    [boxes, outlineModel, xAdjustment, yAdjustment]
  );

  return <HelperContext.Provider value={value}>{children}</HelperContext.Provider>;
}

export function useHelperContext() {
  return useContext(HelperContext);
}
