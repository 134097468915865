import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const MainContext = createContext();

export function MainContextWrapper({ children }) {
  const [token, setToken] = useState();
  const [sceneModels, setSceneModels] = useState([]);
  const [finishedLoading, setFinishedLoading] = useState(0);
  const [canvasModelInformation, setCanvasModelInformation] = useState();
  const [projectInformation, setProjectInformation] = useState();
  const [projectProgress, setProjectProgress] = useState();
  const [projectChecklist, setProjectChecklist] = useState();
  const [bounds, setBounds] = useState();
  const [camera, setCamera] = useState();
  const [controls, setControls] = useState();

  const value = useMemo(
    () => ({
      token,
      setToken,
      sceneModels,
      setSceneModels,
      canvasModelInformation,
      setCanvasModelInformation,
      projectInformation,
      setProjectInformation,
      projectProgress,
      setProjectProgress,
      projectChecklist,
      setProjectChecklist,
      finishedLoading,
      setFinishedLoading,
      bounds,
      setBounds,
      camera,
      setCamera,
      controls,
      setControls
    }),
    [
      canvasModelInformation,
      projectInformation,
      projectProgress,
      sceneModels,
      projectChecklist,
      token,
      finishedLoading,
      bounds,
      camera,
      controls
    ]
  );

  useEffect(() => {
    Promise.resolve(getTokenFromDB()).then((bearer) => {
      setToken(bearer);
    });
  }, []);

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export function useMainContext() {
  return useContext(MainContext);
}

async function getTokenFromDB() {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('procon', 1);

    dbRequest.onsuccess = (e) => {
      const db = e.target.result;
      const tx = db.transaction('bearer', 'readwrite');
      const store = tx.objectStore('bearer');

      const storeRequest = store.get('bearer');
      storeRequest.onsuccess = () => {
        resolve(storeRequest?.result?.bearer);
      };
    };
  });
}
