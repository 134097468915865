import { useProgress } from '@react-three/drei';
import { useEffect, useState } from 'react';
import logo from '../../img/logo2.svg';
import styles from '../../styles/Loader.module.scss';
import Modal from '../other/Modal';
import ProgressBar from '../other/ProgressBar';

export default function Loader() {
  const { active, progress } = useProgress();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShow(false);
    }, 1500);

    if (active) {
      clearTimeout(showTimeout);
      setShow(true);
    }
  }, [active]);

  return (
    <Modal show={show} animation={false} centered backdrop='static' keyboard={false}>
      <div id={styles.loader}>
        <div id={styles.loadingIMG} className='px-3'>
          <img src={logo} alt='Company logo' width={400} height={180} />
        </div>
        <div className='container'>
          <div className='row pt-8 pb-5 justify-content-center'>
            <h5 className={styles.progressHeader}>Loading</h5>
            <span className={styles.loadingDots}>
              <span className={styles.loadingDot}></span>
              <span className={styles.loadingDot}></span>
              <span className={styles.loadingDot}></span>
            </span>
            <div className='mt-2'>
              <ProgressBar percentage={progress} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
