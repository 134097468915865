import { useState } from 'react';
import { useMainContext } from '../../context/MainContext';
import { useStepBasedContext } from '../../context/StepBasedContext';
import eye from '../../img/icons/eye.svg';
import Modal from '../other/Modal';
import { CanvasBlock } from './StepObjectsViewer';

export default function StepObjectsList() {
  const { projectInformation } = useMainContext();
  const { currentPart, currentStepInfo } = useStepBasedContext();
  const [itemModal, setItemModal] = useState();

  if (projectInformation?.setting?.project_type !== 'step based' || !currentPart) return null;
  return (
    <>
      <div className='my-3'>
        <div className='font-bold'>Onderdelenlijst</div>
        {groupedInfo(currentStepInfo?.changes)?.map(({ id, name, count, meshpath }) => {
          return (
            <div key={id} className='flex justify-between mb-1'>
              <div className='flex flex-col'>
                <div>{name}</div>
                <div className='text-xs text-gray-400'>{meshpath}</div>
              </div>
              <div className='flex gap-2 items-center'>
                <div>x{count}</div>
                <div className='cursor-pointer' onClick={() => setItemModal(meshpath)}>
                  <img src={eye} alt={'Close modal'} width={16} />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Modal show={itemModal ? true : false} setShow={setItemModal} closeButton>
        <div className='h-[33vh] bg-[#f1f1f1]'>
          <CanvasBlock modelName={itemModal} />
        </div>
      </Modal>
    </>
  );
}

function groupedInfo(array) {
  const transformedArray = array.reduce((result, item) => {
    const existingItemIndex = result.findIndex((i) => i.name === item.name);

    if (existingItemIndex !== -1) {
      result[existingItemIndex].count += 1;
    } else {
      result.push({ ...item, count: 1 });
    }

    return result;
  }, []);

  return transformedArray;
}
