import { GET, getAuthorizationHeader } from "../axios";

export async function GetProjects(bearer) {
  return GET('projects', '', getAuthorizationHeader(bearer))
}
export async function GetSettingsFromProject(bearer, id) {
  return GET(`projects/${id}`, '', getAuthorizationHeader(bearer))
}
export async function GetModelsFromProject(bearer, id) {
  return GET(`projects/${id}/models`, '', getAuthorizationHeader(bearer))
}
export async function GetProjectProgression(bearer, id) {
  return GET(`userimporttags/project/${id}`, '', getAuthorizationHeader(bearer));

}
