import makerjs from 'makerjs';
import opentype from 'opentype.js';
import { Box3, Vector3 } from 'three';
import { gridCollision } from '../components/scanner/LaserAdjuster';
import fontPath from '../font/laser-Regular.ttf';

export async function autoGenerateDXF(model, withText = undefined, download = false, customFont = {
  name: 'Laser',
  path: fontPath
}) {
  // Get size of model
  const box3 = new Box3().setFromObject(model);
  const box = box3.getSize(new Vector3());

  // Convert to DXF
  let fullModel = {}
  const DXF = new makerjs.models.Rectangle(box.x, box.y)
  fullModel = { ...fullModel, DXF }

  if (withText) {
    withText = withText.toUpperCase()
    const fontPath = customFont.path
    const font = await opentype.load(fontPath)
    const fontSize = 0.025
    let textModel = new makerjs.models.Text(font, withText, fontSize);
    textModel = makerjs.model.moveRelative(textModel, [0, -(fontSize + 0.01)])

    fullModel = { ...fullModel, textModel }
  }

  fullModel = {
    models: fullModel,
    units: makerjs.unitType.Millimeter
  }

  const DXFExport = (makerjs.exporter.toDXF(fullModel)).toString('base64')

  // Download the file if wanted
  if (download) {
    let j = document.createElement("a")
    j.download = `${model.name}_outline_${Date.now()}.DXF`
    j.href = URL.createObjectURL(new Blob([DXFExport]))
    j.click()
  }

  return DXFExport
}
export function getPartPosition(part) {
  const partOverlap = gridCollision(part)
  const partWorldPosition = part?.getWorldPosition(new Vector3());
  const partBox3 = new Box3().setFromObject(part, true);

  return {
    x: partWorldPosition.x - partOverlap.x,
    y: partWorldPosition.y - partOverlap.y,
    z: partBox3.max.z,
  }
}

export function getWHFromPart(part) {
  try {
    const box3 = new Box3().setFromObject(part);
    const box = box3.getSize(new Vector3());

    return { width: box.x, height: box.y };
  } catch (e) {
    return { width: 0, height: 0 };
  }
}
