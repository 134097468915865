import { useState } from 'react';
import Modal from '../other/Modal';

import fullscreen from '../../img/icons/Fit_to_view.svg';
import close from '../../img/icons/close.svg';

export default function ToggleImage({ src, alt }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className='relative'>
        <img src={src} alt={alt} />

        <div
          className='absolute top-0 right-0 m-2 p-1 rounded bg-black bg-opacity-30 cursor-pointer'
          onClick={() => setShow(true)}
        >
          <img src={fullscreen} alt={'toggle fullscreen'} width={16} />
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <div className='relative'>
          <img src={src} alt={alt} className='w-full' />

          <div
            className='absolute top-0 right-0 m-2 p-1 rounded bg-black bg-opacity-30 cursor-pointer'
            onClick={() => setShow(false)}
          >
            <img
              src={close}
              alt={'toggle fullscreen'}
              width={16}
              style={{
                filter: 'invert(100%) sepia(1%) saturate(7500%) hue-rotate(328deg) brightness(104%) contrast(103%)'
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
