import dangerIcon from '../../img/icons/toast/danger.svg';
import infoIcon from '../../img/icons/toast/info.svg';
import successIcon from '../../img/icons/toast/success.svg';
import warningIcon from '../../img/icons/toast/warning.svg';

export default function Notifications({ notifications, setNotifications }) {
  const removeNotification = (i) => setNotifications((n) => n.filter((_n, _i) => _i !== i));

  return (
    <div className='relative'>
      <div className='absolute bottom-4 right-2'>
        {notifications.map((notification, i) => {
          return <Notification key={i} notification={notification} removeNotification={() => removeNotification(i)} />;
        })}
      </div>
    </div>
  );
}
function Notification({ notification, removeNotification }) {
  const close = notification?.close === false;
  let icon = infoIcon,
    background = 'bg-gray-100',
    textColor = 'text-gray-500',
    invert = 'invert';

  switch (notification?.type) {
    case 'danger':
      icon = dangerIcon;
      background = 'bg-red-400';
      textColor = 'text-white';
      invert = '';
      break;
    case 'warning':
      icon = warningIcon;
      background = 'bg-orange-300';
      textColor = 'text-white';
      invert = '';
      break;
    case 'success':
      icon = successIcon;
      background = 'bg-green-400';
      textColor = 'text-white';
      invert = '';
      break;

    default:
      break;
  }

  return (
    <div
      id='toast-interactive'
      className={`w-full max-w-xs p-4 ${textColor} ${background} rounded-lg shadow relative z-50 mt-2`}
      role='alert'
    >
      <div className='flex'>
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg '>
          <img src={icon} alt={`${notification?.type} icon`} height={24} style={{ height: '24px' }} className={invert} />
          <span className='sr-only'>{notification?.type} icon</span>
        </div>
        <div className='ml-3 text-sm font-normal'>
          {notification?.title ? <span className={`mb-1 text-sm font-semibold ${textColor}`}>{notification.title}</span> : null}
          <div className='text-sm font-normal'>{notification?.body || 'Oops, looks like someone is testing in production!'}</div>

          {notification.actions ? (
            <NotificationButtonGroup actions={notification.actions} removeNotification={removeNotification} />
          ) : null}
        </div>

        {!close ? (
          <button
            type='button'
            className={`ml-auto -mx-1.5 -my-1.5 bg-transparent ${textColor}  hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex h-8 w-8`}
            data-dismiss-target='#toast-interactive'
            aria-label='Close'
            onClick={removeNotification}
          >
            <span className='sr-only'>Close</span>
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        ) : null}
      </div>
    </div>
  );
}
function NotificationButtonGroup({ actions, removeNotification }) {
  return (
    <div className='flex justify-end mt-2'>
      {actions?.map((action, i) => {
        return <NotificationButton key={i} action={action} removeNotification={removeNotification} />;
      })}
    </div>
  );
}
function NotificationButton({ action, removeNotification }) {
  const onclick = () => {
    if (action?.onclick) action.onclick();
    if (action.removeOnClick) removeNotification();
  };

  let background = 'bg-gray-100',
    textColor = 'text-gray-500',
    hover = 'hover:bg-blue-700',
    focus = 'focus:ring-blue-300';

  switch (action?.type) {
    case 'danger':
      background = 'border border-red-400';
      textColor = 'text-red-400';
      hover = 'hover:bg-red-700 hover:text-white';
      focus = 'focus:ring-red-300';
      break;
    case 'warning':
      background = 'bg-orange-300';
      textColor = 'text-white';
      hover = 'hover:bg-orange-700';
      focus = 'focus:ring-orange-300';
      break;
    case 'success':
      background = 'bg-green-400';
      textColor = 'text-white';
      hover = 'hover:bg-green-700';
      focus = 'focus:ring-green-300';
      break;

    default:
      break;
  }

  return (
    <button
      onClick={onclick}
      className={`ml-2 px-2 py-1.5 text-xs font-medium text-center ${background} ${textColor} ${hover} ${focus} 
                      rounded-lg  focus:ring-4 focus:outline-none `}
    >
      {action.value}
    </button>
  );
}
