import { Easing, Tween } from '@tweenjs/tween.js';
import { useCallback, useState } from 'react';
import { Box3, Quaternion, Sphere, Vector3 } from 'three';
import { useMainContext } from '../../context/MainContext';
import { useScannerContext } from '../../context/ScannerContext';
import Camera_home_position from '../../img/icons/Camera_home_position.svg';
import Fit_to_view from '../../img/icons/Fit_to_view.svg';
import laser from '../../img/icons/laser.png';
import Zoom_In from '../../img/icons/Zoom_In.svg';
import Zoom_Out from '../../img/icons/Zoom_Out.svg';
import styles from '../../styles/CanvasControls.module.scss';

export default function CanvasControls({ camera, controls, model, bounds }) {
  const { projectInformation } = useMainContext();
  const { setBarcodeScanner } = useScannerContext();
  const [activeCubeSide, setActiveCubeSide] = useState(undefined);

  const cameraToPositionCube = (camera, position) => {
    const distance = getFittedObjectsFrustum(model);
    let posX = 0;
    let posY = 0;
    let posZ = 0;

    if (Array.isArray(position) && position[0] === 'arrow') {
      const sideArrowsInfo = {
        front: {
          left: 'right',
          right: 'left',
          down: 'top',
          up: 'bottom'
        },
        back: {
          left: 'left',
          right: 'right',
          down: 'top',
          up: 'bottom'
        },
        right: {
          left: 'back',
          right: 'front',
          down: 'top',
          up: 'bottom'
        },
        left: {
          left: 'front',
          right: 'back',
          down: 'top',
          up: 'bottom'
        },
        top: {
          left: 'right',
          right: 'left',
          down: 'back',
          up: 'front'
        },
        bottom: {
          left: 'right',
          right: 'left',
          down: 'front',
          up: 'back'
        }
      };

      if (activeCubeSide) {
        position = new Array(sideArrowsInfo[activeCubeSide][position[1]]);
        setActiveCubeSide(position[0]);
      }
    }

    if (position.includes('front')) {
      posZ = distance;
    }
    if (position.includes('right')) {
      posX = distance;
    }
    if (position.includes('back')) {
      posZ = -distance;
    }
    if (position.includes('left')) {
      posX = -distance;
    }
    if (position.includes('top')) {
      posY = distance;
    }
    if (position.includes('bottom')) {
      posY = -distance;
    }

    cameraToPosition(camera, controls, new Vector3(posX, posY, posZ));

    if (Array.isArray(position) && position.length === 1) {
      switch (position[0]) {
        case 'front':
        case 'top':
        case 'bottom':
        case 'back':
        case 'left':
        case 'right':
          setActiveCubeSide(position[0]);
          setArrows(true);
          break;
        default:
          break;
      }
    } else {
      setArrows(false);
    }
  };
  const setArrows = (display) => {
    document.getElementById(styles.rotateCubeArrows).style.display = display ? 'block' : 'none';
  };
  const cameraHome = useCallback(() => {
    cameraToPosition(camera, controls, new Vector3(1.7, 2.2, 4), model);

    setArrows(false);
  }, [camera, controls, model]);
  const cameraFit = () => {
    bounds?.refresh(model).clip().fit();
    setArrows(false);
  };
  const cameraZoom = (distance = 5) => {
    const oldPos = JSON.parse(JSON.stringify(camera.position));
    camera.translateZ(-distance / 10);
    const newPos = JSON.parse(JSON.stringify(camera.position));
    camera.translateZ(distance / 10);

    const obj = { time: 0 };
    new Tween(obj)
      .to({ time: 1 }, 300, Easing.Cubic.Out)
      .onUpdate(() => {
        const currentPos = new Vector3().copy(oldPos);
        currentPos.lerp(newPos, obj.time);
        camera.position.copy(currentPos);
      })
      .start();
  };

  return (
    <div id={styles.viewerControls}>
      <div id={styles.rotateCube} className={styles.cube}>
        <div id={styles.rotatecubefront} className={`${styles.cube__face} ${styles.cube__facefront}`}>
          <strong>Front</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['front'])} />

          <span className={styles.cube__face__side_top} onClick={() => cameraToPositionCube(camera, ['front', 'top'])} />
          <span className={styles.cube__face__side_right} onClick={() => cameraToPositionCube(camera, ['front', 'right'])} />
          <span className={styles.cube__face__side_bottom} onClick={() => cameraToPositionCube(camera, ['front', 'bottom'])} />
          <span className={styles.cube__face__side_left} onClick={() => cameraToPositionCube(camera, ['front', 'left'])} />

          <span
            className={styles.cube__face__cornerTopRight}
            onClick={() => cameraToPositionCube(camera, ['front', 'top', 'right'])}
          />
          <span
            className={styles.cube__face__cornerBottomRight}
            onClick={() => cameraToPositionCube(camera, ['front', 'bottom', 'right'])}
          />
          <span
            className={styles.cube__face__cornerTopLeft}
            onClick={() => cameraToPositionCube(camera, ['front', 'top', 'left'])}
          />
          <span
            className={styles.cube__face__cornerBottomLeft}
            onClick={() => cameraToPositionCube(camera, ['front', 'bottom', 'left'])}
          />
        </div>
        <div id={styles.rotatecubeback} className={`${styles.cube__face} ${styles.cube__faceback}`}>
          <strong>Back</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['back'])} />

          <span className={styles.cube__face__side_top} onClick={() => cameraToPositionCube(camera, ['back', 'top'])} />
          <span className={styles.cube__face__side_left} onClick={() => cameraToPositionCube(camera, ['back', 'left'])} />
          <span className={styles.cube__face__side_bottom} onClick={() => cameraToPositionCube(camera, ['back', 'bottom'])} />
          <span className={styles.cube__face__side_right} onClick={() => cameraToPositionCube(camera, ['back', 'right'])} />

          <span
            className={styles.cube__face__cornerTopRight}
            onClick={() => cameraToPositionCube(camera, ['back', 'top', 'right'])}
          />
          <span
            className={styles.cube__face__cornerBottomRight}
            onClick={() => cameraToPositionCube(camera, ['back', 'bottom', 'right'])}
          />
          <span
            className={styles.cube__face__cornerTopLeft}
            onClick={() => cameraToPositionCube(camera, ['back', 'top', 'left'])}
          />
          <span
            className={styles.cube__face__cornerBottomLeft}
            onClick={() => cameraToPositionCube(camera, ['back', 'bottom', 'left'])}
          />
        </div>
        <div id={styles.rotatecuberight} className={`${styles.cube__face} ${styles.cube__faceright}`}>
          <strong>Right</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['right'])} />

          <span className={styles.cube__face__side_top} onClick={() => cameraToPositionCube(camera, ['right', 'top'])} />
          <span className={styles.cube__face__side_back} onClick={() => cameraToPositionCube(camera, ['right', 'back'])} />
          <span className={styles.cube__face__side_bottom} onClick={() => cameraToPositionCube(camera, ['right', 'bottom'])} />
          <span className={styles.cube__face__side_front} onClick={() => cameraToPositionCube(camera, ['right', 'front'])} />

          <span
            className={styles.cube__face__cornerTopFront}
            onClick={() => cameraToPositionCube(camera, ['right', 'top', 'front'])}
          />
          <span
            className={styles.cube__face__cornerBottomFront}
            onClick={() => cameraToPositionCube(camera, ['right', 'bottom', 'front'])}
          />
          <span
            className={styles.cube__face__cornerTopBack}
            onClick={() => cameraToPositionCube(camera, ['right', 'top', 'back'])}
          />
          <span
            className={styles.cube__face__cornerBottomBack}
            onClick={() => cameraToPositionCube(camera, ['right', 'bottom', 'back'])}
          />
        </div>
        <div id={styles.rotatecubeleft} className={`${styles.cube__face} ${styles.cube__faceleft}`}>
          <strong>Left</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['left'])} />

          <span className={styles.cube__face__side_top} onClick={() => cameraToPositionCube(camera, ['left', 'top'])} />
          <span className={styles.cube__face__side_back} onClick={() => cameraToPositionCube(camera, ['left', 'back'])} />
          <span className={styles.cube__face__side_bottom} onClick={() => cameraToPositionCube(camera, ['left', 'bottom'])} />
          <span className={styles.cube__face__side_front} onClick={() => cameraToPositionCube(camera, ['left', 'front'])} />

          <span
            className={styles.cube__face__cornerTopFront}
            onClick={() => cameraToPositionCube(camera, ['left', 'top', 'front'])}
          />
          <span
            className={styles.cube__face__cornerBottomFront}
            onClick={() => cameraToPositionCube(camera, ['left', 'bottom', 'front'])}
          />
          <span
            className={styles.cube__face__cornerTopBack}
            onClick={() => cameraToPositionCube(camera, ['left', 'top', 'back'])}
          />
          <span
            className={styles.cube__face__cornerBottomBack}
            onClick={() => cameraToPositionCube(camera, ['left', 'bottom', 'back'])}
          />
        </div>
        <div id={styles.rotatecubetop} className={`${styles.cube__face} ${styles.cube__facetop}`}>
          <strong>Top</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['top'])} />

          <span className={styles.cube__face__side_back} onClick={() => cameraToPositionCube(camera, ['top', 'back'])} />
          <span className={styles.cube__face__side_right} onClick={() => cameraToPositionCube(camera, ['top', 'right'])} />
          <span className={styles.cube__face__side_front} onClick={() => cameraToPositionCube(camera, ['top', 'front'])} />
          <span className={styles.cube__face__side_left} onClick={() => cameraToPositionCube(camera, ['top', 'left'])} />

          <span
            className={styles.cube__face__corner_left_back}
            onClick={() => cameraToPositionCube(camera, ['top', 'left', 'back'])}
          />
          <span
            className={styles.cube__face__corner_left_front}
            onClick={() => cameraToPositionCube(camera, ['top', 'left', 'front'])}
          />
          <span
            className={styles.cube__face__corner_right_back}
            onClick={() => cameraToPositionCube(camera, ['top', 'right', 'back'])}
          />
          <span
            className={styles.cube__face__corner_right_front}
            onClick={() => cameraToPositionCube(camera, ['top', 'right', 'front'])}
          />
        </div>
        <div id={styles.rotatecubebottom} className={`${styles.cube__face} ${styles.cube__facebottom}`}>
          <strong>Bottom</strong>
          <span className={styles.cube__faceplate} onClick={() => cameraToPositionCube(camera, ['bottom'])} />

          <span className={styles.cube__face__side_front} onClick={() => cameraToPositionCube(camera, ['bottom', 'front'])} />
          <span className={styles.cube__face__side_right} onClick={() => cameraToPositionCube(camera, ['bottom', 'right'])} />
          <span className={styles.cube__face__side_back} onClick={() => cameraToPositionCube(camera, ['bottom', 'back'])} />
          <span className={styles.cube__face__side_left} onClick={() => cameraToPositionCube(camera, ['bottom', 'left'])} />

          <span
            className={styles.cube__face__corner_left_back}
            onClick={() => cameraToPositionCube(camera, ['bottom', 'left', 'back'])}
          />
          <span
            className={styles.cube__face__corner_left_front}
            onClick={() => cameraToPositionCube(camera, ['bottom', 'left', 'front'])}
          />
          <span
            className={styles.cube__face__corner_right_back}
            onClick={() => cameraToPositionCube(camera, ['bottom', 'right', 'back'])}
          />
          <span
            className={styles.cube__face__corner_right_front}
            onClick={() => cameraToPositionCube(camera, ['bottom', 'right', 'front'])}
          />
        </div>
        <div id={styles.rotateCubeShadow} className={styles.cube__face}></div>
      </div>

      <div id={styles.rotateCubeArrows} style={{ display: 'none' }}>
        <div id={styles.arrowUp} className={styles.arrow} onClick={() => cameraToPositionCube(camera, ['arrow', 'up'])} />
        <div id={styles.arrowRight} className={styles.arrow} onClick={() => cameraToPositionCube(camera, ['arrow', 'right'])} />
        <div id={styles.arrowDown} className={styles.arrow} onClick={() => cameraToPositionCube(camera, ['arrow', 'down'])} />
        <div id={styles.arrowLeft} className={styles.arrow} onClick={() => cameraToPositionCube(camera, ['arrow', 'left'])} />
      </div>

      {projectInformation && (
        <ul>
          <li id='reset-camera' onClick={() => cameraHome()}>
            <img width={20} height={20} src={Camera_home_position} alt='Fit camera to view' />
          </li>

          <li id='fit-camera' onClick={() => cameraFit()}>
            <img width={20} height={20} src={Fit_to_view} alt='Fit camera to view' />
          </li>

          <li id='zoom-in-camera' onClick={() => cameraZoom()}>
            <img width={20} height={20} src={Zoom_In} alt='Zoom in' />
          </li>

          <li id='zoom-out-camera' onClick={() => cameraZoom(-5)}>
            <img width={20} height={20} src={Zoom_Out} alt='Zoom out' />
          </li>

          <li id='barcodeScanner' onClick={() => setBarcodeScanner({ show: true })}>
            <img width={20} height={20} src={laser} alt='Zoom out' />
          </li>
        </ul>
      )}
    </div>
  );
}

function cameraToPosition(camera, controls, position, model = null) {
  if (!camera) return;

  const startPosition = new Vector3().copy(camera.position);
  const startQuaternion = new Quaternion().copy(camera.quaternion);
  const endPoint = model ? model.getWorldPosition(new Vector3(0, 0, 0)) : new Vector3();
  const distance = new Vector3().distanceTo(endPoint);
  const endPosition = new Vector3(position.x, position.y, position.z + distance);

  // final rotation (with lookAt)
  camera.position.copy(endPosition);
  camera.lookAt(endPoint);
  const endQuaternion = new Quaternion().copy(camera.quaternion);

  // revert to original rotation
  camera.position.copy(startPosition);
  camera.quaternion.copy(startQuaternion);

  // Tween
  const obj = { time: 0 };
  new Tween(obj)
    .to({ time: 1 }, 300, Easing.Cubic.Out)
    .onUpdate(() => {
      const currentPos = new Vector3().copy(startPosition);
      currentPos.lerp(endPosition, obj.time);
      camera.position.copy(currentPos);

      const currentQuaternion = new Quaternion().copy(startQuaternion);
      currentQuaternion.slerp(endQuaternion, obj.time);
      camera.quaternion.copy(currentQuaternion);

      const currentPoint = new Vector3().copy(controls.target);
      currentPoint.lerp(endPoint, obj.time);
      controls.target.copy(currentPoint);
    })
    .start();
}
function getFittedObjectsSphere(model) {
  const bBox = new Box3().setFromObject(model);
  const center = new Vector3();
  bBox.getCenter(center);
  return bBox.getBoundingSphere(new Sphere(center));
}
function getFittedObjectsFrustum(model, width = document.body.offsetWidth, height = document.body.offsetHeight) {
  const bSphere = getFittedObjectsSphere(model);
  const aspect = width / height;
  const frustumHeight = aspect > 1 ? 2 * bSphere.radius : (2 * bSphere.radius) / aspect;
  return frustumHeight + 5;
}
