import { Bounds, Environment, OrbitControls, OrthographicCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo, useState } from 'react';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { useMainContext } from '../../context/MainContext';
import { useStepBasedContext } from '../../context/StepBasedContext';

import { Euler, Quaternion } from 'three';
import fullscreen from '../../img/icons/Fit_to_view.svg';
import chevron from '../../img/icons/chevron-left.svg';
import close from '../../img/icons/close-white.svg';

export default function StepObjectsViewer() {
  const { projectInformation } = useMainContext();
  const { currentPart } = useStepBasedContext();

  const [bigPreview, setBigPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  if (projectInformation?.setting?.project_type !== 'step based' || !currentPart) return null;
  return (
    <div className={`absolute top-14 left-2 rounded-md border-2 z-50 bg-[#f1f1f1]`}>
      {showPreview ? (
        <div
          className={`relative duration-300
          ${bigPreview ? 'w-[calc(100vw-1rem)] h-[calc(100vh-4rem)]' : 'w-96 h-48'}`}
        >
          <CanvasBlock />

          <div className='absolute top-0 right-0 m-2 flex'>
            <div className='p-1 rounded bg-black bg-opacity-30 cursor-pointer mr-2' onClick={() => setShowPreview((np) => !np)}>
              <img
                src={chevron}
                alt={'toggle preview'}
                width={16}
                className={`${showPreview ? '' : 'rotate-180'} duration-300`}
              />
            </div>
            <div className='p-1 rounded bg-black bg-opacity-30 cursor-pointer' onClick={() => setBigPreview((bp) => !bp)}>
              <img src={bigPreview ? close : fullscreen} alt={'toggle fullscreen'} width={16} />
            </div>
          </div>
        </div>
      ) : (
        <div className='p-1 rounded bg-black bg-opacity-30 cursor-pointer' onClick={() => setShowPreview((np) => !np)}>
          <img src={chevron} alt={'toggle preview'} width={16} className={`${showPreview ? '' : 'rotate-180'} duration-300`} />
        </div>
      )}
    </div>
  );
}
export function CanvasBlock({ modelName }) {
  return (
    <Canvas className='rounded-md'>
      <color attach='background' args={[0xf1f1f1]} />
      <OrthographicCamera makeDefault />

      <Environment preset='city' />
      <Bounds fit clip observe damping={0} margin={1.2}>
        <Model modelName={modelName} />
      </Bounds>

      <OrbitControls makeDefault />
    </Canvas>
  );
}
function Model({ modelName }) {
  const { sceneModels } = useMainContext();
  const { currentPart } = useStepBasedContext();

  const modelObject = useMemo(() => {
    const obj = sceneModels?.current.getObjectByName(modelName);
    if (obj) {
      const clone = SkeletonUtils.clone(obj);

      // Update the part rotation with it's original world rotation
      const worldQuaternion = obj.getWorldQuaternion(new Quaternion());
      const worldEuler = new Euler().setFromQuaternion(worldQuaternion, 'XYZ');
      clone.rotation.copy(worldEuler);

      return clone;
    }
  }, [modelName, sceneModels]);

  return (
    <Suspense>
      {modelName || modelObject ? (
        <primitive object={modelObject} dispose={null} />
      ) : currentPart ? (
        <primitive object={currentPart} dispose={null} />
      ) : null}
    </Suspense>
  );
}
