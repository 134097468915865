import { useCallback, useState } from 'react';
import { useMainContext } from '../context/MainContext';
import styles from '../styles/Menu.module.scss';
import About from './Modals/About';

export default function Menu() {
  const [aboutModal, setAboutModal] = useState(false);
  const { token, setToken, projectInformation } = useMainContext();

  const backToProjects = useCallback(() => window.location.reload(), []);

  const toggleFullscreen = () => window.api.send('fullscreen');
  const logout = () =>
    Promise.resolve(logoutDB())
      .then(() => setToken())
      .then(() => window.location.reload());

  return (
    <>
      <About show={aboutModal} hide={() => setAboutModal(false)} />

      <div id={styles.logoMenu}>
        <div id={styles.logo}></div>
        <div id={styles.logoDivider}></div>
        <div id={styles.hamburger} className='mx-2'>
          <input type='checkbox' />
          <div id={styles.hamburgerWrapper}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul id={styles.menu}>
            <li>
              <button id='toggleFullscreenOn' onClick={toggleFullscreen}>
                Go fullscreen
              </button>
              <button id='toggleFullscreenOff' className='hidden' onClick={toggleFullscreen}>
                Exit fullscreen
              </button>
            </li>
            <li>
              <button id='menu-about' onClick={() => setAboutModal(true)}>
                About
              </button>
            </li>
            {token && projectInformation ? (
              <li>
                <button id='menu-logout' onClick={backToProjects}>
                  Back to projects
                </button>
              </li>
            ) : null}
            <li>
              <hr />
            </li>
            <li>
              <button id='menu-logout' onClick={logout}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export async function logoutDB() {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('procon');

    dbRequest.onsuccess = (e) => {
      const db = e.target.result;
      const tx = db.transaction('bearer', 'readwrite');
      const store = tx.objectStore('bearer');

      const storeRequest = store.delete('bearer');
      storeRequest.onsuccess = () => {
        resolve();
      };
    };
  });
}
