import { useEffect, useState } from 'react';
import { GET, GetVirtutecLogin } from '../../api/axios';
import { useMainContext } from '../../context/MainContext';
import proconLogo from '../../img/logo2.svg';
import bgLeft from '../../img/virtutec/background-left.svg';
import bgRight from '../../img/virtutec/background-right.svg';
import virtutecLogo from '../../img/virtutec/logo.svg';
import FloatingInput from '../other/FloatingInput';

export default function AuthCheck() {
  const { setToken } = useMainContext();
  const [errorMsg, setErrorMsg] = useState();
  const dev = process.env.NODE_ENV === 'development';

  const loginUser = (e) => {
    e.preventDefault();

    Promise.resolve(
      GetVirtutecLogin({
        email: e.target.email.value,
        password: e.target.password.value
      })
    )
      .then(async ({ token }) => {
        const { bearer } = await GET('/sso/login', `token=${token}`);
        if (e.target.remember_me.checked) {
          saveTokenInDB(bearer);
        }
        setToken(bearer);
      })
      .catch((e) => {
        console.error(e);
        setErrorMsg(e?.response?.data?.error || 'Something went wrong..');
      });
  };
  const registerClick = () => window.api.send('open-in-browser', 'https://dashboard.virtutec.live/register/');
  const forgotPasswordClick = () => window.api.send('open-in-browser', 'https://dashboard.virtutec.live/password-forgot/');

  useEffect(() => {
    const dbRequest = indexedDB.open('procon');

    dbRequest.onupgradeneeded = (e) => {
      const db = e.target.result;
      if (!db.objectStoreNames.contains('bearer')) {
        db.createObjectStore('bearer', { keyPath: 'id' });
      }
    };
  }, []);

  return (
    <>
      <div
        className='h-full'
        style={{
          background: `url(${bgLeft}) 0 100% no-repeat fixed,url(${bgRight}) 100% 100% no-repeat fixed`
        }}
      >
        <main className='flex h-full items-center justify-center flex-col'>
          <img src={virtutecLogo} alt='logo' width={500} height={250} className='mb-8 px-8' />
          <div className='border border-gray-200 bg-white px-6 py-5 rounded-lg w-full max-w-lg'>
            <div className='text-gray-400 text-center mb-6'>
              <div>Log in to continue to:</div>
              <img src={proconLogo} alt='logo' width={500} height={250} className='w-32 mx-auto' />
            </div>
            <div className='col-md-5'>
              <form onSubmit={loginUser} className='needs-validation loginCard' noValidate>
                <FloatingInput
                  id='email'
                  name='email'
                  placeholder={'Email'}
                  defaultValue={dev ? 'j.dijk@provrex.nl' : null}
                  required
                />
                <FloatingInput
                  id='password'
                  name='password'
                  type='password'
                  placeholder={'Password'}
                  defaultValue={dev ? 'As01an5$' : null}
                  required
                  onChange={(e) => {
                    e.target.classList.remove('is-invalid');
                  }}
                />
                <div className='text-center text-sm'>{errorMsg}</div>
                <div className='flex justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='checked-checkbox'
                      type='checkbox'
                      value=''
                      name='remember_me'
                      className='w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-orange-500 appearance-none checked:bg-orange-400 cursor-pointer'
                    />
                    <label htmlFor='checked-checkbox' className='ml-2 text-sm cursor-pointer'>
                      Remember me
                    </label>
                  </div>
                  <button type='button' onClick={forgotPasswordClick} className='font-bold text-orange-300'>
                    Forgot password?
                  </button>
                </div>
                <button
                  type='submit'
                  className='w-full my-7 text-center items-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                  aria-label='Login'
                >
                  Login
                </button>
                <div className='registerLine text-center mt-5'>
                  {`Don't have an account? `}
                  <button type='button' onClick={registerClick} className='font-bold text-orange-300'>
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

function saveTokenInDB(bearer) {
  const dbRequest = indexedDB.open('procon');

  dbRequest.onsuccess = (e) => {
    const db = e.target.result;
    const tx = db.transaction(['bearer'], 'readwrite');
    const store = tx.objectStore('bearer');
    store.put({ id: 'bearer', bearer });
  };

  dbRequest.onerror = (e) => {
    console.dir(e);
  };
}
