import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box3, Vector3 } from 'three';
import { useHelperContext } from '../../context/HelperContext';
import { useMainContext } from '../../context/MainContext';
import min from '../../img/icons/min.svg';
import plus from '../../img/icons/plus.svg';
import { autoGenerateDXF, getPartPosition, getWHFromPart } from '../../js/DXFHelper';
import styles from '../../styles/CanvasControls.module.scss';

export default function LaserAdjuster() {
  const { sceneModels } = useMainContext();
  const { setBoxes, outlineModel } = useHelperContext();

  const projectorSerial = localStorage.getItem('projectorSerial');
  const coordinateSystem = localStorage.getItem('coordinateSystem');

  const [laser, setLaser] = useState(
    JSON.parse(localStorage.getItem('laserConfig')) || {
      x: 0,
      y: 0,
      zoom: 1000
    }
  );

  const coordinateSystemChange = (e) => {
    localStorage.setItem('coordinateSystem', e.target.value);
  };
  const projectorSerialChange = (e) => {
    localStorage.setItem('projectorSerial', e.target.value);
  };
  const useFontChange = (e) => {
    localStorage.setItem('DXFFont', e.target.checked.toString());
  };

  const outlineModelModel = useMemo(
    () => sceneModels?.current.getObjectByProperty('uuid', outlineModel) || sceneModels.current,
    [outlineModel, sceneModels]
  );
  const modelDXF = useMemo(() => autoGenerateDXF(outlineModelModel), [outlineModelModel]);

  const laserMoveUp = useCallback(
    () =>
      setLaser((l) => {
        return { ...l, y: l.y++ };
      }),
    []
  );
  const laserMoveRight = useCallback(
    () =>
      setLaser((l) => {
        return { ...l, x: l.x++ };
      }),
    []
  );
  const laserMoveDown = useCallback(
    () =>
      setLaser((l) => {
        return { ...l, y: l.y-- };
      }),
    []
  );
  const laserMoveLeft = useCallback(
    () =>
      setLaser((l) => {
        return { ...l, x: l.x-- };
      }),
    []
  );

  // Model outline to laser
  useEffect(() => {
    localStorage.setItem('laserConfig', JSON.stringify(laser));

    const position = getPartPosition(outlineModelModel);
    const { width, height } = getWHFromPart(outlineModelModel);
    setBoxes(() => {
      return [
        {
          id: 0,
          position,
          width,
          height,
          color: 'red'
        }
      ];
    });
  }, [laser, modelDXF, outlineModelModel, setBoxes]);

  return (
    <div className='flex flex-cols-2 justify-center items-center'>
      <div id={styles['d-pad']}>
        <span className={styles.up} onClick={laserMoveUp}></span>
        <span className={styles.right} onClick={laserMoveRight}></span>
        <span className={styles.down} onClick={laserMoveDown}></span>
        <span className={styles.left} onClick={laserMoveLeft}></span>
      </div>

      <div className='ml-7'>
        <div className='mb-3 relative'>
          <input
            type='text'
            id='floating_ps'
            className='block px-2.5 pb-1.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 
                border border-gray-300 rounded-lg border-1
                appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer'
            placeholder=' '
            defaultValue={projectorSerial}
            onChange={projectorSerialChange}
          />
          <label
            htmlFor='floating_ps'
            className='absolute text-sm text-gray-500 bg-gray-50 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                peer-focus:px-2 peer-focus:text-green-600 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:bg-gray-50 peer-focus:rounded-lg'
          >
            Projector Serial
          </label>
        </div>
        <div className='mb-3 relative'>
          <input
            type='text'
            id='floating_cs'
            className='block px-2.5 pb-1.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 
                border border-gray-300 rounded-lg border-1
                appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer'
            placeholder=' '
            defaultValue={coordinateSystem}
            onChange={coordinateSystemChange}
          />
          <label
            htmlFor='floating_cs'
            className='absolute text-sm text-gray-500 bg-gray-50 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                peer-focus:px-2 peer-focus:text-green-600 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:bg-gray-50 peer-focus:rounded-lg'
          >
            Coordinate System
          </label>
        </div>

        <div className='mb-3'>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              X
            </span>
            <input
              className='rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-green-500 focus:border-green-500 block flex-1 min-w-0 w-full text-sm border-gray-300 px-2.5 py-1.5'
              placeholder='X'
              value={laser.x}
              type='number'
              onChange={(e) =>
                setLaser((l) => {
                  return { ...l, x: parseInt(e.target.value) || 0 };
                })
              }
            />
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              Y
            </span>
            <input
              className='rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-green-500 focus:border-green-500 block flex-1 min-w-0 w-full text-sm border-gray-300 px-2.5 py-1.5'
              placeholder='Y'
              value={laser.y}
              type='number'
              onChange={(e) =>
                setLaser((l) => {
                  return { ...l, y: parseInt(e.target.value) || 0 };
                })
              }
            />
          </div>
        </div>

        <div className='flex'>
          <button
            className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-l-md'
            onClick={() =>
              setLaser((l) => {
                return { ...l, zoom: l.zoom-- };
              })
            }
          >
            <img src={min} alt='Zoom out a bit' />
          </button>
          <input
            placeholder='Zoom level'
            className='inline-flex text-center flex-1 p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border-l-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
            value={laser.zoom}
            type='number'
            onChange={(e) =>
              setLaser((l) => {
                return { ...l, zoom: parseInt(e.target.value) || 0 };
              })
            }
          />
          <button
            className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md'
            onClick={() =>
              setLaser((l) => {
                return { ...l, zoom: l.zoom++ };
              })
            }
          >
            <img src={plus} alt='Zoom in a bit' />
          </button>
        </div>

        <div className='flex flex-col mt-4'>
          <div className='flex  items-center '>
            <input
              id='default-checkbox'
              type='checkbox'
              value=''
              defaultChecked={localStorage.getItem('DXFFont') === 'true'}
              className='w-3 h-3 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 focus:ring-2 '
              onChange={useFontChange}
            />
            <label htmlFor='default-checkbox' className='ml-2 text-sm  text-gray-500'>
              Show partname with laser
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export function gridCollision(model) {
  if (!model) return 0;

  const modelPosition = model.getWorldPosition(new Vector3());
  const originBox = new Box3().setFromObject(model);
  const min = originBox.min;

  let x = 0;
  if (min.x <= 0) {
    x = modelPosition.x + Math.abs(min.x);
  } else if (min.y > 0) {
    x = modelPosition.x - Math.abs(min.x);
  }

  let y = 0;
  if (min.y <= 0) {
    y = modelPosition.y + Math.abs(min.y);
  } else if (min.y > 0) {
    y = modelPosition.y - Math.abs(min.y);
  }

  return { x, y };
}
