import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './components/reportWebVitals';
import { HelperContextWrapper } from './context/HelperContext';
import { MainContextWrapper } from './context/MainContext';
import { NotificationContextWrapper } from './context/NotificationContext';
import { ScannerWrapper } from './context/ScannerContext';
import { StepBasedContextWrapper } from './context/StepBasedContext';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelperContextWrapper>
      <NotificationContextWrapper>
        <MainContextWrapper>
          <StepBasedContextWrapper>
            <ScannerWrapper>
              <App />
            </ScannerWrapper>
          </StepBasedContextWrapper>
        </MainContextWrapper>
      </NotificationContextWrapper>
    </HelperContextWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
