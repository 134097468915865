import { Bounds, Environment, Sky, useBounds } from '@react-three/drei';
import { Canvas as ThreeCanvas, useFrame } from '@react-three/fiber';
import { update as TWEEN_UPDATE } from '@tweenjs/tween.js';
import { useCallback, useEffect, useRef } from 'react';
import { useMainContext } from '../../context/MainContext';
import CanvasControls from './CanvasControls';
import LaserBoxHelper from './LaserBoxHelper';
import Loader from './Loader';
import ModelLoader from './ModelLoader';
import CustomOrbitControls, { zoomToCursor } from './OrbitControls';

export default function Canvas() {
  const { setSceneModels, bounds, setBounds, camera, setCamera, controls, setControls } = useMainContext();
  const group = useRef();

  const onWheel = useCallback((e) => zoomToCursor(e), []);

  useEffect(() => setCamera(controls?.object), [controls, setCamera]);
  useEffect(() => setSceneModels(group), [group, setSceneModels]);

  return (
    <>
      <ThreeCanvas onWheel={onWheel} camera={{ position: [1.7, 2.2, 4], fov: 50 }}>
        <Sky sunPosition={[0, 1, 0]} />

        <Bounds fit clip margin={1.2}>
          <BoundsGetter setBounds={setBounds}>
            <ModelLoader groupRef={group} />
          </BoundsGetter>
        </Bounds>

        <LaserBoxHelper />

        {/* City preset because "warehouse" is too bright */}
        <Environment preset='city' />

        <CustomOrbitControls setOrbitControls={setControls} />
        <TweenController />
      </ThreeCanvas>

      <CanvasControls camera={camera} controls={controls} model={group?.current} bounds={bounds} />
      <Loader />
    </>
  );
}
function BoundsGetter({ children, setBounds }) {
  const bounds = useBounds();

  useEffect(() => {
    setBounds(bounds);
  }, [bounds, setBounds]);

  return children;
}
function TweenController() {
  useFrame(() => TWEEN_UPDATE());
  return null;
}
