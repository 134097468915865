import { createContext, useContext, useState } from 'react';
import ScannerContentBlock from '../components/scanner';

const ScannerContext = createContext();

export function ScannerWrapper({ children }) {
  const [barcodeScanner, setBarcodeScanner] = useState();

  return (
    <ScannerContext.Provider
      value={{
        barcodeScanner,
        setBarcodeScanner
      }}
    >
      {barcodeScanner ? <ScannerContentBlock /> : null}
      {children}
    </ScannerContext.Provider>
  );
}

export function useScannerContext() {
  return useContext(ScannerContext);
}
