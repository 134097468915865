import { useCallback, useMemo, useState } from 'react';
import { useStepBasedContext } from '../../context/StepBasedContext';
import checklistIcon from '../../img/icons/info.svg';
import styles from '../../styles/Sidebar.module.scss';

import docx from '../../img/icons/files/docx.svg';
import file from '../../img/icons/files/file.svg';
import pdf from '../../img/icons/files/pdf.svg';
import xls from '../../img/icons/files/xls.svg';
import ToggleImage from '../other/ToggleImage';
import StepObjectsList from './StepObjectsList';

export default function StepSidebar({ info }) {
  const { steps, currentStepInfo } = useStepBasedContext();
  const [toggled, setToggled] = useState(true);

  const lastStep = useMemo(() => steps[steps?.length - 1]?.id === info?.id, [info?.id, steps]);

  return (
    <div id={styles.sidebarWrapper} className={toggled ? styles.hidden : ''}>
      <div id={styles.sidebar}>
        <div className={styles.header}>Information</div>
        <div id={styles.scrollable} className='container h-full'>
          <div className='row pt-2 flex flex-col justify-between h-full'>
            <div className='mb-3 mx-2'>
              <InfoBlock info={info} />
              {info?.checklist ? <ChecklistBlock stepId={info.id} checklist={info.checklist} /> : null}
              {info?.files ? <FilesBlock files={info.files} /> : null}

              {currentStepInfo?.effect && currentStepInfo?.changes?.length ? <StepObjectsList /> : null}
            </div>

            {lastStep ? <LastStepExtras /> : null}
          </div>
        </div>
      </div>

      <ul className={styles.sidebarHidden}>
        <li onClick={() => setToggled((t) => !t)} className={toggled ? styles.isActive : ''}>
          <img src={checklistIcon} alt='Toggle checklist' width={25} height={25} />
        </li>
      </ul>
    </div>
  );
}
function InfoBlock({ info }) {
  return (
    <div className=''>
      <div>
        <strong>{info?.name}</strong>
      </div>
      <div>{info?.description}</div>
    </div>
  );
}
function ChecklistBlock({ stepId, checklist }) {
  return (
    <div className='border-t border-t-grey mt-3 pt-3'>
      <strong>Action points:</strong>
      {checklist?.map((cl) => {
        return <ChecklistItem key={cl.id} stepId={stepId} item={cl} />;
      })}
    </div>
  );
}
function ChecklistItem({ stepId, item }) {
  const { setSteps } = useStepBasedContext();

  const onChange = useCallback(
    (e) => {
      const status = e.target.checked ? 'done' : 'not started';
      setSteps((steps) => {
        return steps.map((s) => {
          if (s.id === stepId) {
            s.checklist.map((c) => {
              if (c.id === item.id) {
                c.status = status;
              }
              return c;
            });
          }
          return s;
        });
      });
    },
    [setSteps, stepId, item.id]
  );

  return (
    <div className='pt-1 px-2 flex items-center'>
      <input
        type='checkbox'
        id={`checklistitem-${item.id}`}
        className='mr-2 cursor-pointer'
        checked={item.status === 'done'}
        onChange={onChange}
      />
      <label
        title={item?.importtag?.full_component_code}
        htmlFor={`checklistitem-${item.id}`}
        className='form-check-label cursor-pointer'
      >
        {item?.value}
      </label>
    </div>
  );
}
function FilesBlock({ files }) {
  return (
    <div className='border-t border-t-grey mt-3 pt-3'>
      <strong>Files:</strong>
      {files?.map((f) => (
        <div key={f.id} className='overflow-hidden mb-1'>
          {(() => {
            let icon = file;
            switch (f.type) {
              case 'img':
                return <ToggleImage src={f.value} alt={f.title} />;
              case 'video':
                return <video src={f.value} controls />;
              case 'pdf':
                icon = pdf;
                break;
              case 'xls':
                icon = xls;
                break;
              case 'docx':
                icon = docx;
                break;

              default:
                break;
            }

            return (
              <a href={f.value} className='flex' target='_blank' rel='noopener noreferrer'>
                <img src={icon} alt={f.title} />
                <span className='truncate ml-1' title={f.title}>
                  {f.title}
                </span>
              </a>
            );
          })()}
        </div>
      ))}
    </div>
  );
}
function LastStepExtras() {
  const { steps } = useStepBasedContext();

  const disabled = useMemo(() => {
    const uncheckedSteps = [];
    steps?.forEach((s) => {
      if (!s?.checklist) return;

      let incompleteCount = 0;
      s?.checklist?.forEach((c) => {
        if (c.id && c?.status !== 'done') incompleteCount++;
      });

      if (incompleteCount !== 0 && incompleteCount !== s?.checklist?.length)
        uncheckedSteps.push({
          step: s.id,
          items: s?.checklist?.filter((c) => c.status !== 'done')
        });
    });
    return uncheckedSteps;
  }, [steps]);
  const backToProjects = useCallback(() => window.location.reload(), []);

  return (
    <div className='mb-3 mx-2 text-end'>
      <div className='text-center text-gray-500 mb-3'>
        <small>
          {disabled?.length ? (
            <>
              <div>
                To complete the project, all required action points must be finished. Please verify that you have done so.
              </div>
              <ul>
                {disabled?.map((d) => {
                  const stepNum = steps.findIndex((s) => s.id === d.step);

                  return (
                    <li key={d.step}>
                      There {d.items.length === 1 ? 'is' : 'are'} <strong>{d.items.length} </strong>
                      incomplete action point{d.items.length === 1 ? ' ' : 's'} in <strong>step {stepNum + 1}</strong>.
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            'To finish and submit the project, please click the button. You will then be redirected to the project overview automatically.'
          )}
        </small>
      </div>
      <button
        onClick={backToProjects}
        disabled={disabled?.length}
        className='bg-green-500 rounded px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
         focus-visible:outline-green-600  disabled:opacity-50'
      >
        Submit
      </button>
    </div>
  );
}
