import { Easing, Tween, removeAll } from '@tweenjs/tween.js';
import { useEffect, useMemo, useState } from 'react';
import { Color, Euler, Group, Quaternion } from 'three';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { useMainContext } from '../../context/MainContext';
import { useStepBasedContext } from '../../context/StepBasedContext';
import backward from '../../img/icons/backward.svg';
import end from '../../img/icons/end.svg';
import forward from '../../img/icons/forward.svg';
import start from '../../img/icons/start.svg';
import StepObjectVisualizer from './StepObjectVisualizer';
import StepSidebar from './StepSidebar';

export default function StepDrawer() {
  const { projectInformation, sceneModels, finishedLoading, camera, controls } = useMainContext();
  const { steps, currentPart, setCurrentPart, currentStepInfo, setCurrentStepInfo, setCurrentPartRefs } = useStepBasedContext();

  const [currentStep, setCurrentStep] = useState(0);

  const buttons = useMemo(
    () => [
      {
        id: 0,
        name: 'start',
        onClick: () => setCurrentStep(0),
        disabled: currentStep === 0
      },
      {
        id: 1,
        name: 'backward',
        onClick: () =>
          setCurrentStep((cs) => {
            if (cs > 0) return cs - 1;
            return 0;
          }),
        disabled: currentStep === 0
      },
      {
        id: 2,
        name: 'forward',
        onClick: () =>
          setCurrentStep((cs) => {
            if (cs < steps.length - 1) return cs + 1;
            return steps.length - 1;
          }),
        disabled: currentStep === steps.length - 1
      },
      {
        id: 3,
        name: 'end',
        onClick: () => setCurrentStep(steps.length - 1),
        disabled: currentStep === steps.length - 1
      }
    ],
    [currentStep, steps.length]
  );
  const appliedChanges = useMemo(() => {
    const changes = [];
    steps
      .map((s, i) => {
        if (s.isOverview) {
          if (i === currentStep) return s;
          return { id: s.id };
        }
        return s;
      })
      .forEach((s, i) => {
        if (i <= currentStep) changes.push(s.changes?.map((c) => c.meshpath).flat());
      });
    return changes.flat();
  }, [currentStep, steps]);

  // Apply model changes
  useEffect(() => {
    const projectModelsAmount = projectInformation?.links?.filter((pi) => pi.rel === 'model');
    if (finishedLoading === projectModelsAmount?.length) {
      sceneModels.current?.traverse((child) => (child.visible = false));
      sceneModels.current?.traverse((child) => {
        if (appliedChanges?.includes(child.name) || appliedChanges?.includes('*')) {
          child.visible = true;
          child.traverseAncestors((anc) => (anc.visible = true));
          child.traverse((c) => (c.visible = true));
        }
      });
    }
  }, [appliedChanges, currentStepInfo?.effect, finishedLoading, projectInformation?.links, sceneModels]);

  // Apply model effect
  useEffect(() => {
    sceneModels.current?.traverse((child) => unHighlightElementBreathing(child));
    sceneModels.current?.traverse((child) => {
      if (
        currentStepInfo?.changes
          ?.map((c) => c.meshpath)
          ?.flat()
          ?.includes(child.name)
      ) {
        if (currentStepInfo?.effect) {
          highlightElementBreating(child);
        }
      }
    });
  }, [currentStepInfo, sceneModels]);

  // Create a partGroup
  useEffect(() => {
    const moveArray = [];
    const part = new Group();
    let y = 0;

    currentStepInfo?.changes.forEach((object) => {
      const obj = sceneModels?.current.getObjectByName(object?.meshpath);
      if (obj) {
        obj.userData.position = obj.position.clone();
        moveArray.push(obj);

        const clone = SkeletonUtils.clone(obj);

        // Update the part rotation with it's original world rotation
        const worldQuaternion = obj.getWorldQuaternion(new Quaternion());
        const worldEuler = new Euler().setFromQuaternion(worldQuaternion, 'XYZ');
        clone.rotation.copy(worldEuler);

        //
        clone.position.set(0, y, 0);

        part.add(clone);
      }
    });

    if (currentStepInfo?.effect && part) {
      setCurrentPartRefs(moveArray);
      setCurrentPart(part);
    } else {
      setCurrentPartRefs();
      setCurrentPart();
    }
  }, [currentStepInfo, sceneModels, setCurrentPart, setCurrentPartRefs]);

  useEffect(() => setCurrentStepInfo(steps[currentStep]), [currentStep, steps, setCurrentStepInfo]);

  return (
    <>
      <div className='mb-4 flex flex-col justify-center items-center absolute bottom-0 w-full'>
        {currentStepInfo?.name ? (
          <div className='mb-2 bg-green-500 rounded px-3 text-white font-medium'>
            {`${currentStep + 1}. ${currentStepInfo?.name}`}
          </div>
        ) : null}
        <div className='flex'>
          {buttons?.map((b, i) => (
            <Button key={b.id} {...b} />
          ))}
        </div>
      </div>
      <Progression total={steps.length - 1} now={currentStep} />

      <StepObjectVisualizer />
      <StepSidebar info={currentStepInfo} />
    </>
  );
}
function Button({ name, onClick, disabled }) {
  const icon = useMemo(() => {
    switch (name) {
      case 'start':
        return start;
      case 'backward':
        return backward;
      case 'forward':
        return forward;
      case 'end':
        return end;
      default:
        return;
    }
  }, [name]);

  return (
    <div
      className={`bg-green-500 p-2 mx-1 rounded hover:bg-green-700 hover:cursor-pointer duration-300 select-none
    ${disabled ? 'pointer-events-none bg-green-400' : ''}
    `}
      onClick={onClick}
    >
      <img src={icon} alt={name} width={20} height={20} />
    </div>
  );
}
function Progression({ total, now }) {
  const percentage = useMemo(() => (now / total) * 100, [now, total]);

  return (
    <div className='absolute bottom-0 left-0 right-0 overflow-hidden duration-300'>
      <div className='w-full bg-gray-700 h-1 hover:h-5 duration-300'>
        <div className='bg-green-600 h-full duration-300' style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
}

export function highlightElementBreating(element) {
  const materialEffect = (mesh) => {
    mesh.visible = true;

    if (mesh?.isMesh) {
      if (mesh.oldMesh) mesh.material = mesh.oldMesh;

      const oldMesh = mesh.material.clone();
      const m = mesh.material.clone();

      m.emissiveIntensity = 0.75;
      m.transparent = true;

      mesh.material = m;
      mesh.oldMesh = oldMesh;

      const duration = 2500;
      const startColor = new Color(oldMesh.color);
      const endColor = new Color(0x04cc58);
      const tweenAnimation = new Tween({
        color: startColor,
        opacity: 1
      })
        .to({ color: endColor, opacity: 0.75 }, duration)
        .easing(Easing.Exponential.InOut)
        .onUpdate(({ color, opacity }) => {
          mesh.material.color.copy(color);
          mesh.material.opacity = opacity;
        })
        .repeat(Infinity)
        .yoyo(true);
      tweenAnimation.start();
    }
  };

  if (element) element.traverse((e) => materialEffect(e));
}
export function unHighlightElementBreathing(element) {
  removeAll();

  element.traverseVisible((e) => {
    if (e?.isMesh && e?.oldMesh) {
      e.material = e.oldMesh;
    }
  });
}
