import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useMainContext } from './MainContext';

const StepBasedContext = createContext();

export function StepBasedContextWrapper({ children }) {
  const { projectInformation } = useMainContext();

  const MOREFUCKINGSTEPS = useMemo(
    () => [
      {
        meshpath: 'mesh_1',
        name: ''
      },
      {
        meshpath: 'mesh_0',
        name: ''
      },
      {
        meshpath: 'mesh_3',
        name: ''
      },
      {
        meshpath: 'mesh_2',
        name: ''
      },
      {
        meshpath: 'mesh_5',
        name: ''
      },
      {
        meshpath: 'mesh_4',
        name: ''
      },
      {
        meshpath: 'mesh_7',
        name: ''
      },
      {
        meshpath: 'mesh_6',
        name: ''
      },
      {
        meshpath: 'mesh_8',
        name: ''
      },
      {
        meshpath: 'mesh_9',
        name: ''
      },
      {
        meshpath: 'mesh_10',
        name: ''
      },
      {
        meshpath: 'mesh_11',
        name: ''
      },
      {
        meshpath: 'mesh_12',
        name: ''
      },
      {
        meshpath: 'mesh_13',
        name: ''
      },
      {
        meshpath: 'mesh_15',
        name: ''
      },
      {
        meshpath: 'mesh_14',
        name: ''
      },
      {
        meshpath: 'mesh_16',
        name: ''
      },
      {
        meshpath: 'mesh_18',
        name: ''
      },
      {
        meshpath: 'mesh_17',
        name: ''
      },
      {
        meshpath: 'mesh_20',
        name: ''
      },
      {
        meshpath: 'mesh_19',
        name: ''
      },
      {
        meshpath: 'mesh_22',
        name: ''
      },
      {
        meshpath: 'mesh_21',
        name: ''
      },
      {
        meshpath: 'mesh_24',
        name: ''
      },
      {
        meshpath: 'mesh_23',
        name: ''
      },
      {
        meshpath: 'mesh_25',
        name: ''
      },
      {
        meshpath: 'mesh_27',
        name: ''
      },
      {
        meshpath: 'mesh_26',
        name: ''
      },
      {
        meshpath: 'mesh_29',
        name: ''
      },
      {
        meshpath: 'mesh_28',
        name: ''
      },
      {
        meshpath: 'mesh_31',
        name: ''
      },
      {
        meshpath: 'mesh_30',
        name: ''
      },
      {
        meshpath: 'mesh_33',
        name: 'Lasstiftbout_-_M4_x_10'
      },
      {
        meshpath: 'mesh_32',
        name: 'Lasstiftbout_-_M4_x_10'
      },
      {
        meshpath: 'mesh_35',
        name: 'Lasstiftbout_-_M3_x_12'
      },
      {
        meshpath: 'mesh_34',
        name: 'Lasstiftbout_-_M3_x_12'
      },
      {
        meshpath: 'mesh_37',
        name: 'Lasstiftbout_-_M3_x_8'
      },
      {
        meshpath: 'mesh_36',
        name: 'Lasstiftbout_-_M3_x_8'
      },
      {
        meshpath: 'mesh_39',
        name: 'Torx_pin_verzonken_kop_-_M4_x_12'
      },
      {
        meshpath: 'mesh_38',
        name: 'Torx_pin_verzonken_kop_-_M4_x_12'
      },
      {
        meshpath: 'mesh_40',
        name: 'Zeskantmoer_flens_&_vertanding_-_M4'
      },
      {
        meshpath: 'mesh_41',
        name: 'Zeskantmoer_flens_&_vertanding_-_M4'
      },
      {
        meshpath: 'mesh_42',
        name: 'Zeskantmoer_flens_&_vertanding_-_M3'
      },
      {
        meshpath: 'mesh_43',
        name: 'Zeskantmoer_flens_&_vertanding_-_M3'
      },
      {
        meshpath: 'mesh_44',
        name: 'Kabelbinderhouder_M3'
      },
      {
        meshpath: 'mesh_45',
        name: 'Code_BB_Strip_V2_CBB6'
      },
      {
        meshpath: 'mesh_46',
        name: 'Code_BB_20mm_RVS_Beugel'
      },
      {
        meshpath: 'mesh_47',
        name: 'Code_BB_15RVS_Deksel1_(schoefbaar)_Code_BB_Deksel1_Dicht'
      },
      {
        meshpath: 'mesh_48',
        name: 'Code_BB_15RVS_Deksel1_(schoefbaar)_Code_BB_Deksel1_Dicht'
      },
      {
        meshpath: 'mesh_49',
        name: 'Code_BB_Deksel2_Ronde_schakelaar'
      },
      {
        meshpath: 'mesh_50',
        name: 'Code_BB_Deksel2_Ronde_schakelaar'
      },
      {
        meshpath: 'mesh_51',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_56',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_52',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_54',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_55',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_53',
        name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins'
      },
      {
        meshpath: 'mesh_60',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_61',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_58',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_59',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_62',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_57',
        name: 'LDPR064-4975X70_4000K,_S6'
      },
      {
        meshpath: 'mesh_63',
        name: 'Adels_-_3voudig_-_Blokklem_met_aarde'
      },
      {
        meshpath: 'mesh_64',
        name: '230V_AanUit_Schakelaar'
      },
      {
        meshpath: 'mesh_65',
        name: '230V_AanUit_Schakelaar'
      },
      {
        meshpath: 'mesh_68',
        name: 'LDPR030-1975X43_4000K,_S6'
      },
      {
        meshpath: 'mesh_69',
        name: 'LDPR030-1975X43_4000K,_S6'
      },
      {
        meshpath: 'mesh_70',
        name: 'LDPR030-1975X43_4000K,_S6'
      },
      {
        meshpath: 'mesh_66',
        name: 'LDPR030-1975X43_4000K,_S6'
      },
      {
        meshpath: 'mesh_67',
        name: 'LDPR030-1975X43_4000K,_S6'
      },
      {
        meshpath: 'mesh_71',
        name: '230V_Wandcontactdoos'
      },
      {
        meshpath: 'mesh_72',
        name: '230V_Wandcontactdoos'
      },
      {
        meshpath: 'Code_BB_Basis_V4_CBB6',
        name: 'Code_BB'
      },
      {
        meshpath: 'Code_BB_Venster_CBB6',
        name: 'Code_BB'
      }
    ],
    []
  );

  const [steps, setSteps] = useState([]);
  const [currentPart, setCurrentPart] = useState();
  const [currentPartRefs, setCurrentPartRefs] = useState();
  const [currentStepInfo, setCurrentStepInfo] = useState();
  const value = useMemo(
    () => ({
      steps,
      setSteps,
      currentPart,
      setCurrentPart,
      currentPartRefs,
      setCurrentPartRefs,
      currentStepInfo,
      setCurrentStepInfo
    }),
    [currentPart, currentPartRefs, currentStepInfo, steps]
  );

  useEffect(
    () =>
      setSteps(
        projectInformation?.id === 1
          ? [
              {
                id: 0,
                name: 'Voorbereiding',
                changes: [
                  { id: 0, meshpath: 'Kabelbinderhouder_M3', name: 'Kabelbinderhouder_M3' },
                  { id: 1, meshpath: 'Code_BB_Strip_V2_CBB6', name: 'Code_BB_Strip_V2_CBB6' },
                  { id: 2, meshpath: 'Code_BB_20mm_RVS_Beugel', name: 'Code_BB_20mm_RVS_Beugel' },
                  {
                    id: 3,
                    meshpath: 'Code_BB_15RVS_Deksel1_(schoefbaar)_Code_BB_Deksel1_Dicht',
                    name: 'Code_BB_15RVS_Deksel1_(schoefbaar)_Code_BB_Deksel1_Dicht'
                  },
                  { id: 4, meshpath: 'Code_BB_Deksel2_Ronde_schakelaar', name: 'Code_BB_Deksel2_Ronde_schakelaar' },
                  { id: 5, meshpath: 'Code_BB_Basis_V4_CBB6', name: 'Code_BB_Basis_V4_CBB6' },
                  { id: 6, meshpath: 'Lasstiftbout_-_M4_x_10', name: 'Lasstiftbout_-_M4_x_10' },
                  { id: 7, meshpath: 'Lasstiftbout_-_M3_x_12', name: 'Lasstiftbout_-_M3_x_12' },
                  { id: 8, meshpath: 'Lasstiftbout_-_M3_x_8', name: 'Lasstiftbout_-_M3_x_8' },
                  { id: 9, meshpath: 'Torx_pin_verzonken_kop_-_M4_x_12', name: 'Torx_pin_verzonken_kop_-_M4_x_12' },
                  { id: 10, meshpath: '230V_Schakelaar_RVS_IP65_19_mm_3_pins', name: '230V_Schakelaar_RVS_IP65_19_mm_3_pins' },
                  { id: 11, meshpath: '230V_Wandcontactdoos', name: '230V_Wandcontactdoos' },
                  { id: 12, meshpath: '230V_AanUit_Schakelaar', name: '230V_AanUit_Schakelaar' },
                  { id: 13, meshpath: 'Adels_-_3voudig_-_Blokklem_met_aarde', name: 'Adels_-_3voudig_-_Blokklem_met_aarde' },
                  { id: 14, meshpath: 'Zeskantmoer_flens_&_vertanding_-_M4', name: 'Zeskantmoer_flens_&_vertanding_-_M4' },
                  { id: 15, meshpath: 'Zeskantmoer_flens_&_vertanding_-_M3', name: 'Zeskantmoer_flens_&_vertanding_-_M3' }

                  // 'Code_BB_Venster_CBB6'
                ],
                description: 'Tijd om te beginnen aan de schakelkast. Denk goed aan de actiepunten.',
                checklist: [
                  { id: 4, value: 'Veiligheidsvoorwaarden toegepast' },
                  { id: 2, value: 'Paneel juist uitgelijnd op werkbank' },
                  { id: 0, value: 'Gereedschap gereed' },
                  { id: 1, value: 'Onderdelen gereed' }
                ],
                files: [
                  { id: 1, value: './test.pdf', title: 'Extra-Information.pdf', type: 'pdf' },
                  {
                    id: 0,
                    value: './img/step-demo/Instructievideo.png',
                    type: 'img',
                    title: 'Instructievideo'
                  }
                ]
              },
              {
                id: 1,
                name: 'Plaatsing onderdeel',
                changes: [{ id: 0, meshpath: 'LDPR064-4975X70_4000K,_S6', name: 'LDPR064-4975X70_4000K,_S6' }],
                effect: 'pulse',
                description: 'Plaats hier uw aanvullende montageinstructies'
              },
              {
                id: 5,
                name: 'Plaatsing onderdeel',
                changes: [{ id: 0, meshpath: 'LDPR030-1975X43_4000K,_S6', name: 'LDPR030-1975X43_4000K,_S6' }],
                effect: 'pulse',
                description: 'Plaats hier uw aanvullende montageinstructies'
              },
              {
                id: 6,
                name: 'Afronden',
                changes: [{ id: 0, meshpath: '*', name: '*' }],
                description: 'Plaats hier uw aanvullende montageinstructies',
                files: [
                  {
                    id: 0,
                    value: './img/step-demo/21gr1.gif',
                    type: 'img',
                    title: 'Good job!'
                  }
                ]
              }
            ]
          : [
              ...[
                ...MOREFUCKINGSTEPS.map(({ meshpath, name }, i) => {
                  return {
                    id: i,
                    name: 'Plaatsing onderdeel',
                    effect: 'pulse',
                    changes: [{ id: 0, meshpath, name }]
                  };
                })
              ]
            ]
      ),
    [MOREFUCKINGSTEPS, projectInformation?.id]
  );

  return <StepBasedContext.Provider value={value}>{children}</StepBasedContext.Provider>;
}
export function useStepBasedContext() {
  return useContext(StepBasedContext);
}
