import { useState } from 'react';
import { useScannerContext } from '../../context/ScannerContext';
import CloseButton from '../other/CloseButton';
import Modal from '../other/Modal';
import DangerZone from './DangerZone';
import LaserAdjuster from './LaserAdjuster';
import Scanner from './Scanner';

export default function ScannerContentBlock() {
  const { barcodeScanner, setBarcodeScanner } = useScannerContext();
  const [show] = useState(barcodeScanner ? true : false);
  const hideBarcodeScannerModal = () => setBarcodeScanner(false);

  return (
    <Modal show={show}>
      <div className='p-5'>
        <div className='pb-7'>
          <CloseButton onClick={hideBarcodeScannerModal} />
        </div>

        <LaserAdjuster />

        <hr className='my-6' />

        <Scanner onPartNext={hideBarcodeScannerModal} />

        <DangerZone />
      </div>
    </Modal>
  );
}
