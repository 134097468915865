import closeBtn from '../../img/icons/close.svg';

export default function CloseButton({ onClick = () => {} }) {
  return (
    <>
      <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          onClick={onClick}
        >
          <span className='sr-only'>Close</span>
          <img src={closeBtn} alt='Close button' />
        </button>
      </div>
    </>
  );
}
