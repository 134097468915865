
import { GET, getAuthorizationHeader, PATCH } from "../axios";

export async function GetModelTags(bearer, id) {
  return GET(`importtagmodels/${id}/getByModel`, '', getAuthorizationHeader(bearer));
}
export async function UpdateModelProgress(bearer, modelId, tagId, status) {
  return PATCH(`userimporttags/model/${modelId}/tag/${tagId}`, {
    status
  }, getAuthorizationHeader(bearer));
}
