import { useState } from 'react';
import { UpdateModelProgress } from '../../api/model';
import { useMainContext } from '../../context/MainContext';
import { useNotificationContext } from '../../context/NotificationContext';
import check from '../../img/icons/check.svg';
import { highlightElement, unHighlightElement } from '../ThreeD/ModelLoader';
import Spinner from '../other/Spinner';

export default function Scanner({ onPartNext }) {
  const { token, sceneModels, projectChecklist, projectProgress, setProjectProgress } = useMainContext();
  const { setNotifications } = useNotificationContext();
  const [qrCode, setQrCode] = useState();

  return (
    <>
      <form
        className='flex w-full'
        onSubmit={(e) => {
          e.preventDefault();
          if (qrCode?.text) {
            formSubmit(
              sceneModels,
              qrCode.text,
              projectChecklist,
              projectProgress,
              token,
              setProjectProgress,
              onPartNext,
              setNotifications
            );
          }
        }}
      >
        <input
          name='qrCodeInput'
          placeholder='Manual barcode input'
          className='inline-flex text-center flex-1 p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-l-md focus:ring-blue-500 focus:border-blue-500'
          defaultValue={qrCode?.text}
          onChange={(e) =>
            setQrCode((qr) => {
              return { ...qr, text: e.target.value };
            })
          }
        />
        <button
          type='submit'
          className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md'
          onChange={() =>
            setQrCode((oldCode) => {
              return { ...oldCode, loading: false, isInvalid: false };
            })
          }
          autoComplete='off'
          autoFocus={true}
          disabled={qrCode?.loading}
        >
          {qrCode?.loading ? <Spinner color='green-400' /> : <img src={check} alt='Zoom in a bit' />}
        </button>
      </form>
    </>
  );
}

export function formSubmit(
  sceneModels,
  text,
  projectChecklist,
  projectProgress,
  token,
  setProjectProgress,
  onPartNext,
  setNotifications
) {
  const powerOff = () => {
    window.api.send('power-off', {
      coordinateSystem: localStorage.getItem('coordinateSystem'),
      projectorSerial: sessionStorage.getItem('projectorSerial')
    });
  };
  const checkAllPlaced = () => {
    const finishedItemsIds = projectProgress.filter((p) => p.status === 'done').map((p) => p.import_tags_model_id);
    const nextTags = partInChecklist.filter((b) => !finishedItemsIds.includes(b.id));

    if (!nextTags.length) {
      const newNoti = {
        type: 'warning',
        title: 'Oops!',
        body: `No more spaces found for "${text}". It has been placed ${partInChecklist.length} time. Please place it in the red bin.`
      };
      setNotifications((oldNotis) => [...oldNotis, newNoti]);
      return false;
    }
    return nextTags[0]?.mesh;
  };
  const sendConfirmMsg = () => {
    const newNoti = {
      title: 'Placement confirmation',
      body: `I confirm that I have placed the part "${text}" and can continue.`,
      close: false,
      actions: [
        {
          value: 'Deny',
          type: 'danger',
          onclick: () => {
            const newNoti2 = {
              type: 'warning',
              title: 'Oops!',
              body: `Please place the part "${text}" back on the cart.`
            };
            setNotifications((oldNotis) => [...oldNotis, newNoti2]);
            unHighlightElement(part, true);
            powerOff();
          },
          removeOnClick: true
        },
        {
          value: 'Confirm',
          type: 'success',
          onclick: () => {
            doPlacementAction();
            unHighlightElement(part);
            powerOff();
          },
          removeOnClick: true
        }
      ]
    };
    setNotifications((oldNotis) => [...oldNotis, newNoti]);
  };
  const doPlacementAction = () => {
    const tag = projectChecklist.find((pc) => pc?.mesh === partName);
    if (tag) {
      Promise.resolve(UpdateModelProgress(token, tag?.model_id, tag.id, 'done')).then((newPp) => {
        setProjectProgress((oldPp) => {
          // Remove it from the local array if exsists
          const filteredPp = oldPp.filter((pp) => pp.import_tags_model_id !== tag.id);
          return [...filteredPp, newPp];
        });
      });
    }
  };

  let part, partName;
  const partInChecklist = projectChecklist.filter((pc) => pc?.importtag?.barcode_number === text);

  if (partInChecklist.length) {
    partName = checkAllPlaced();
    if (!partName) return;

    part = sceneModels?.current.getObjectByName(partName);

    if (part) {
      highlightElement(part);

      sendConfirmMsg();
      onPartNext();
    }
  } else {
    const newNoti = {
      type: 'danger',
      title: 'Oops!',
      body: `Model part "${text}" not found!`
    };
    setNotifications((oldNotis) => [...oldNotis, newNoti]);
  }
}
