import axios from "axios";
import { logoutDB } from "../components/Menu";

const axiosServiceVirtutec = axios.create({
  baseURL: process.env.REACT_APP_VIRTUTEC_API
});
const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API
});
axiosService.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    return
    Promise.resolve(logoutDB()).then(() =>
      window.location.reload())
  }
  return error;
});

export async function GET(url, params, headers = {}) {
  return axiosService.get(`${url}?${params}`, headers).then((res) => res.data);
}
export async function POST(url, data, headers = {}) {
  return axiosService.post(url, data, headers).then((res) => res.data);
}
export async function PATCH(url, data, headers = {}) {
  return axiosService.patch(url, data, headers).then((res) => res.data);
}
export async function GetVirtutecLogin(data) {
  return axiosServiceVirtutec.post('/login', data).then((res) => res.data);
}

// Helpers
export function getAuthorizationHeader(bearer) {
  return { headers: { Authorization: `Bearer ${bearer}` } }
}


export default axiosService;
