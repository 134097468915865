export default function FloatingInput({
  id = 'floating_outlined',
  placeholder,
  defaultValue = '',
  type = 'text',
  required = false
}) {
  return (
    <div className='mb-3 relative'>
      <input
        id={id}
        className='block px-2.5 pb-1.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 
                    border border-gray-300 rounded-lg border-1  
                    appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer'
        placeholder=' '
        required={required}
        defaultValue={defaultValue}
        type={type}
      />
      <label
        htmlFor={id}
        className='absolute text-sm text-gray-500 bg-gray-50 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:px-2 peer-focus:text-orange-600 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:bg-gray-50 peer-focus:rounded-lg'
      >
        {placeholder}
        {required ? '*' : ''}
      </label>
    </div>
  );
}
