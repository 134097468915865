import { useEffect, useState } from 'react';
import { GetModelTags } from '../api/model';
import { GetProjectProgression } from '../api/project';
import { useMainContext } from '../context/MainContext';

export default function ProgressBar() {
  const {
    token,
    canvasModelInformation,
    projectInformation,
    projectProgress,
    setProjectProgress,
    projectChecklist,
    setProjectChecklist
  } = useMainContext();

  const [hover, setHover] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [stepAmount, setStepAmount] = useState('');

  useEffect(() => {
    if (!projectChecklist) {
      const promiseArray = [];
      canvasModelInformation?.forEach((model) => {
        promiseArray.push(GetModelTags(token, model.id));
      });
      Promise.all(promiseArray).then((checklistItems) => {
        setProjectChecklist(checklistItems.flat());
      });
    }
  }, [canvasModelInformation, projectChecklist, projectProgress, setProjectChecklist, setProjectProgress, token]);
  useEffect(() => {
    if (!projectProgress) {
      Promise.resolve(GetProjectProgression(token, projectInformation.id)).then((e) => setProjectProgress(e));
    }
  }, [projectInformation.id, projectProgress, setProjectProgress, token]);
  useEffect(() => {
    if (projectProgress && projectChecklist) {
      const itemsAmount = projectChecklist.length;
      const itemsDone = projectProgress?.filter((p) => p.status === 'done').length;

      const total = (itemsDone / itemsAmount) * 100;
      setPercentage(total);
      setStepAmount(`${itemsDone}/${itemsAmount}`);
    }
  }, [projectProgress, projectChecklist]);

  if (projectInformation?.setting?.project_type === 'step based') return null;

  return (
    <div
      id='progressBar'
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? { transition: '.3s' } : { transition: '.3s', paddingTop: '15px' }}
      className='absolute bottom-0 left-0 right-0 overflow-hidden'
      title={hover ? `Progression: ${stepAmount}` : ''}
    >
      <div
        className='w-full bg-gray-700'
        style={hover ? { transition: '.3s', height: '30px' } : { transition: '.3s', height: '5px' }}
      >
        <div className='bg-green-600 h-full' style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
}
