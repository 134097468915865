import { useMemo } from 'react';
import { useHelperContext } from '../../context/HelperContext';
import { useMainContext } from '../../context/MainContext';
import power from '../../img/icons/power.svg';

export default function DangerZone() {
  const powerOff = async () => {
    const projectorSerial = sessionStorage.getItem('projectorSerial'),
      coordinateSystem = localStorage.getItem('coordinateSystem');
    window.api.send('power-off', {
      coordinateSystem,
      projectorSerial
    });
  };

  return (
    <>
      <div className='w-100 mt-9 mb-7' style={{ borderTop: '2px dashed #db6161', position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: '-10pt',
            left: '50%',
            backgroundColor: 'white',
            padding: '0 10px',
            transform: 'translate(-50%, 0)',
            color: '#db6161',
            fontWeight: '900'
          }}
        >
          Careful!
        </span>
      </div>

      <OutlineModelSelector />

      <div className='flex align-center justify-center'>
        <button
          type='button'
          className='inline-flex items-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2'
          onClick={powerOff}
        >
          <img src={power} alt='Power button icon' />
          <div className='ml-2'>Turn off the laser</div>
        </button>
      </div>
    </>
  );
}

function OutlineModelSelector() {
  const { sceneModels } = useMainContext();
  const { outlineModel, setOutlineModel } = useHelperContext();

  const outlineOptions = useMemo(() => {
    const options = [
      {
        uuid: sceneModels.current.uuid,
        name: sceneModels.current.name
      }
    ];

    const maxDepth = 3;
    const addChildren = (object, currentDepth) => {
      object?.children.forEach((child) => {
        if (child) {
          options.push({
            uuid: child.uuid,
            name: child.name
          });
          if (currentDepth < maxDepth) {
            addChildren(child, currentDepth + 1);
          }
        }
      });
    };
    addChildren(sceneModels.current, 0);

    return options;
  }, [sceneModels]);

  return (
    <div className='mb-3 relative'>
      <select
        id='floating_outlineModel'
        className='block px-2.5 pb-1.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 
          border border-gray-300 rounded-lg border-1
          appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer'
        onChange={(e) => setOutlineModel(e.target.value)}
        defaultValue={outlineModel}
      >
        {outlineOptions?.map((option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.name}
          </option>
        ))}
      </select>
      <label
        htmlFor='floating_outlineModel'
        className='absolute text-sm text-gray-500 bg-gray-50 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2
          peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
          peer-focus:px-2 peer-focus:text-green-600 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:bg-gray-50 peer-focus:rounded-lg'
      >
        Outline generated from
      </label>
    </div>
  );
}
