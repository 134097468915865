import { useCallback, useEffect, useRef, useState } from 'react';
import { useMainContext } from '../../context/MainContext';
import { useNotificationContext } from '../../context/NotificationContext';
import { formSubmit } from './Scanner';

export default function BackgroundScanner() {
  const { token, sceneModels, projectChecklist, projectProgress, setProjectProgress } = useMainContext();
  const { setNotifications } = useNotificationContext();
  const [, setCode] = useState();
  const resetTimeout = useRef();

  const _captureKeyPress = useCallback(
    (event) => {
      if (event.target.nodeName !== 'INPUT') {
        event.preventDefault();
        if (event.key.length > 1) return;

        setCode((c) => {
          const newCode = c === undefined ? event.key : `${c}${event.key}`;
          console.log(`[CODE] ${newCode}`);

          clearTimeout(resetTimeout.current);
          resetTimeout.current = setTimeout(() => {
            formSubmit(
              sceneModels,
              newCode,
              projectChecklist,
              projectProgress,
              token,
              setProjectProgress,
              () => {},
              setNotifications
            );
            setCode();
          }, 1000);

          return newCode;
        });
      }
    },
    [projectChecklist, projectProgress, sceneModels, setNotifications, setProjectProgress, token]
  );

  useEffect(() => {
    document.addEventListener('keydown', _captureKeyPress);
    return () => document.removeEventListener('keydown', _captureKeyPress);
  }, [_captureKeyPress]);

  return null;
}
