import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import close from '../../img/icons/close-white.svg';

export default function Modal({ children, show = false, setShow = () => {}, closeButton = false }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                {children}

                {closeButton ? <CloseButton onClick={setShow} /> : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {/* Hack to prevent stupid warning: "Error: There are no focusable elements inside the <FocusTrap />" */}
        <button className='opacity-0 absolute bottom-0'></button>
      </Dialog>
    </Transition.Root>
  );
}
function CloseButton({ onClick }) {
  return (
    <div
      className='absolute top-2 right-2 w-[25px] h-[25px] flex justify-center items-center text-white bg-gray-500 rounded-md cursor-pointer'
      onClick={() => onClick(false)}
    >
      <img src={close} alt={'Close modal'} width={16} />
    </div>
  );
}
