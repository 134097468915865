import logo from '../../img/logo2.svg';
import styles from '../../styles/modals/About.module.scss';
import CloseButton from '../other/CloseButton';
import Modal from '../other/Modal';

export default function About({ show, hide }) {
  const version = '1.0.1';
  const openOnline = () => window.api.send('open-in-browser', 'https://procon.live');

  return (
    <Modal id={styles.aboutModal} show={show} onHide={hide} size='lg'>
      <CloseButton onClick={hide} />
      <div className='row about-inner text-center mx-auto pt-4 pb-8'>
        <img className='mx-auto' src={logo} alt='Procon logo' height={100} width={300} />
        <div className='mt-3'>
          <p>
            <button onClick={openOnline} className='text-green-400'>
              procon.live
            </button>
            <br />
            <a href='mailto:support@virtutec.live' target='_blank' rel='noreferrer' className='text-green-400'>
              support@virtutec.live
            </a>
            <br />
            <small>Version: {version}</small>
          </p>
          <p className='pt-5'>
            <small>
              Copyright &copy; {new Date().getFullYear()} Virtutec BV <br />
              <a
                href='https://salcon.live/assets/210528%20Privacy%20Policy%20UK.pdf'
                className='text-green-400'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy
              </a>
              {' - '}
              <a
                href='https://salcon.live/assets/210528%20Terms%20of%20Use%20salcon.nl%20UK.pdf'
                target='_blank'
                rel='noopener noreferrer'
                className='text-green-400'
              >
                Terms of use
              </a>
            </small>
          </p>
        </div>
      </div>
    </Modal>
  );
}
