import { createContext, useContext, useMemo, useState } from 'react';
import Notifications from '../components/other/Notifications';

const NotificationContext = createContext();

export function NotificationContextWrapper({ children }) {
  const [notifications, setNotifications] = useState([]);

  const value = useMemo(
    () => ({
      notifications,
      setNotifications
    }),
    [notifications, setNotifications]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}

      <Notifications notifications={notifications} setNotifications={setNotifications} />
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}
