import { useCallback, useState } from 'react';
import { useHelperContext } from '../context/HelperContext';
import { useMainContext } from '../context/MainContext';
import checklistIcon from '../img/icons/Checklist.svg';
import styles from '../styles/Sidebar.module.scss';
import Spinner from './other/Spinner';

export default function Sidebar() {
  const { canvasModelInformation, projectInformation, projectProgress, projectChecklist, sceneModels } = useMainContext();
  const [toggled, setToggled] = useState(true);

  if (projectInformation?.setting?.project_type === 'step based') return null;
  return (
    <div id={styles.sidebarWrapper} className={toggled ? styles.hidden : ''}>
      <div id={styles.sidebar}>
        <div className={styles.header}>Checklist</div>
        <div id={styles.scrollable} className='container h-100'>
          <div className='row pt-2 h-100'>
            <div className='mb-3'>
              {canvasModelInformation?.map((cmi) => {
                return projectChecklist?.length ? (
                  projectChecklist
                    .filter((ci) => sceneModels?.current?.getObjectByName(ci?.mesh))
                    ?.map((c, i) => {
                      return (
                        <ChecklistItem
                          key={c.id}
                          item={c}
                          model={cmi}
                          isChecked={
                            projectProgress?.find((pp) => pp.status === 'done' && pp.import_tags_model_id === c.id) ? true : false
                          }
                        />
                      );
                    })
                ) : (
                  <div key={cmi.id} className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner color='green-600' />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <ul className={styles.sidebarHidden}>
        <li onClick={() => setToggled((t) => !t)} className={toggled ? styles.isActive : ''}>
          <img src={checklistIcon} alt='Toggle checklist' width={30} height={30} />
        </li>
      </ul>
    </div>
  );
}

function ChecklistItem({ item, model, isChecked }) {
  const { sceneModels, setProjectProgress, token } = useMainContext();
  const { setBoxes } = useHelperContext();

  const onChange = useCallback(
    (e) => {
      const status = e.target.checked ? 'done' : 'not started';

      setProjectProgress((oldPp) => {
        return [
          ...oldPp.map((pp) => {
            if (pp.import_tags_model_id === item.id) {
              pp.status = status;
            }
            return pp;
          })
        ];
      });
    },
    [item.id, setProjectProgress]
  );

  return (
    <div className='pt-1 px-2 flex items-center'>
      <input
        type='checkbox'
        id={`checklistitem-${item.id}`}
        className='mr-2 cursor-pointer'
        checked={isChecked}
        onChange={onChange}
      />
      <label
        title={item?.importtag?.full_component_code}
        htmlFor={`checklistitem-${item.id}`}
        className='form-check-label cursor-pointer'
      >
        {item?.importtag?.full_component_code}
      </label>
    </div>
  );
}
